import React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const AdsSlot = dynamic(() => import('./AdsSlot'), {
    ssr: false
});
interface Props {
    id?: string;
    margin?: string;
}

interface ContainerProps {
    $margin?: string;
}

const Container = styled.div<ContainerProps>`
    width: 300px;
    height: 250px;
    background: #f9f9f9;
    @media (max-width: 992px) {
        width: 300px;
        height: 200px;
    }
    ${({ $margin }) => `margin: ${$margin ? $margin : 'auto'}`}
`;

const Rectangle = ({ id, margin = 'auto' }: Props): JSX.Element => {
    if (!id) return null;
    return (
        <Container $margin={margin} id={`container-div-gpt-ad-${id}`} key={id}>
            <AdsSlot slotId={id} />
        </Container>
    );
};

export default Rectangle;
